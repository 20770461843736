<template>
  <div class="profile">
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-6 col-md-6">
        <h1>{{ title }}</h1>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <div class="row">
              <base-input
                name="Brand Name"
                v-model="contact.firstName"
                placeholder="Brand Name"
                rules="required"
                type="text"
              />
            </div>
            <base-input
              name="Phone"
              placeholder="Phone"
              type="tel"
              v-model="contact.phone"
            />
            <div class="button-row">
              <button
                type="submit"
                class="btn btn-primary large"
                :class="processing ? 'processing' : ''"
              >
                Save
              </button>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="image">
          <img src="@/assets/images/contact.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { UPDATE_USER } from "@/graphql/common/mutations";
import { MESSAGES } from "@/_helpers/notifications";
export default {
  data() {
    return {
      title: "Contact",
      processing: false,
      contact: {
        firstName: null,
        lastName: null,
        phone: null,
      },
    };
  },
  methods: {
    ...mapActions(["updateUser"]),
    async onSubmit() {
      this.processing = true;
      await this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: this.contact,
        })
        .then((data) => {
          if (data.data.updateUser.value != "") {
            this.notify("success", MESSAGES.SUCCESS);
            this.updateUser().then(() => {
              this.$router.push("/brand/");
              this.processing = false;
            });
            return;
          }
        });
    },
  },
};
</script>
<style lang="scss">
.LoginRegister {
  .contentWrapper {
    padding-top: 0 !important;
  }
  .profile {
    width: 100%;
  }
}
</style>
